<template>
  <div class="hero">
    <Container class="hero-content">
      <Headline
        v-if="headline"
        class="page-hero-headline"
        :class="`page-hero-headline--${headlineColor}`"
        :size="1"
        alignment="center"
        no-margin
      >
        {{ headline }}
      </Headline>
      <p class="hero-claim" :class="`hero-claim--${claimColor}`">
        {{ claim }}
      </p>
      <Button
        v-for="link in links"
        :key="link.id"
        class="page-hero-button"
        color="navy"
        size="medium"
        :rounded-corners="false"
        :to="link.ingredients[0].linkUrl"
      >
        {{ link.ingredients[0].value }}
      </Button>
    </Container>
    <picture>
      <source
        v-for="imgType in mobileImagesByType"
        :key="`mobile${imgType.type}`"
        :srcset="imgType.srcsetstring"
        :type="imgType.type"
        media="(max-width: 960px)"
      />
      <source
        v-for="imgType in desktopImagesByType"
        :key="`desktop${imgType.type}`"
        :srcset="imgType.srcsetstring"
        sizes="(min-width: 1920px) 1920px"
        :type="imgType.type"
      />
      <img role="none" :alt="alt" :src="src" class="hero-background-image" />
    </picture>
  </div>
</template>

<script>
  import Headline from "../Headline"
  import Button from "../Button"
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    components: { Headline, Button },
    mixins: [createFormatArray],
    props: {
      src: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
        default: null,
      },
      headline: {
        type: String,
        default: null,
      },
      desktopSrcset: {
        type: Array,
        default: () => [],
      },
      mobileSrcset: {
        type: Array,
        default: () => [],
      },
      claim: {
        type: String,
        default: null,
      },
      links: {
        type: Array,
        default: null,
      },
      headlineColor: {
        type: String,
        default: "black",
      },
      claimColor: {
        type: String,
        default: "gray",
      },
    },
    computed: {
      mobileImagesByType() {
        return this.createFormatArray(this.mobileSrcset)
      },
      desktopImagesByType() {
        return this.createFormatArray(this.desktopSrcset)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .page-hero-button {
    margin-top: $base-spacing * 2;
  }
  .hero {
    position: relative;
    box-sizing: border-box;
    padding-top: 80px;
    height: 50em;
    margin-bottom: $gutter;
  }

  .hero-claim {
    margin: 0;
    margin-top: 0.5em;
    @include type("lg");
    &--gray {
      color: $gray-text;
    }
    &--white {
      color: white;
    }
    &--black {
      color: $onyx;
    }
  }
  .hero-content {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .hero-background-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .page-hero-headline {
    margin: 0;
    &--white {
      color: white;
    }
    &--black {
      color: $onyx;
    }
  }
</style>
